/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/css/style.css";
import "./src/css/global.css";
import "./src/css/noPurge.css";

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }
}

// export const onRouteUpdate = () => {
//     navigator.serviceWorker.register('/sw.js').then((reg) => {
//         reg.update();
//     });
// };

// export const onServiceWorkerUpdateReady = () => {
//     window.location.reload(true)
// };
